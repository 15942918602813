import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import {
  getAzureUsers,
  getAllRegistryKeys,
  getCompanyInfo,
  getConditionalAccessResults,
  getDeviceApplications,
  getDeviceProcesses,
  getDeviceVulnerabilities,
  getLatestHealthchecks,
  getTenantLogs,
  getUserHandbookStatuses,
  getUserTrainingStatuses,
  listDevices,
  listTenants,
  listUsersAndRoles,
  getPoamItems,
  getSspControls,
  getControlStatuses,
  getDbUsers,
  listDbDevices,
  getSspScore,
} from "../services/atomus-internal-apis.service";
import {
  AzureUserInfo,
  CompanyInformation,
  ConditionalAccessPolicyResults,
  DeviceApplication,
  DeviceApplicationData,
  LegacyDeviceHealthcheck,
  DeviceProcess,
  DeviceVulnerability,
  RegKeyConfiguration,
  SignInIp,
  TenantListResponse,
  TenantLogType,
  UsbDriveMountedEvent,
  UserAndRoles,
  UserEmployeeHandbookStatus,
  UserTrainingStatus,
  HealthcheckRow,
  HealthcheckStatus,
  AegisUser,
  SspScore,
  Device,
} from "../types/atomus-internal-apis.types";
import { useMicrosoftToken } from "./tokens.hooks";
import { v4 as uuidv4 } from "uuid";
import {
  AssessmentObjective,
  CompanyAoStatusItem,
} from "../types/atomus-internal-apis.types";

import { POAMSpecificItem } from "../types/documentation.types";
import { IntuneMobileDeviceTypes } from "../constants/internal-apis.constants";

export function useCompanyInfoQuery(
  tenantId: string
): UseQueryResult<CompanyInformation, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<CompanyInformation, Error>(
    [tenantId, "company-info"],
    async () => {
      if (!tenantId) {
        throw new Error("Could not get tenantId from params");
      }
      const token = await getInternalApiToken();
      return getCompanyInfo(token, tenantId);
    }
  );
}

export function useUsersQuery(
  tenantId: string
): UseQueryResult<AzureUserInfo[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<AzureUserInfo[], Error>(
    [tenantId, "aegis-users"],
    async () => {
      if (!tenantId) {
        throw new Error("Could not get tenantId from params");
      }
      const token = await getInternalApiToken();
      return getAzureUsers(token, tenantId);
    }
  );
}

export function useDbUsersQuery(
  tenantId: string
): UseQueryResult<AegisUser[], Error> {
  return useQuery([tenantId, "db-users"], async () => {
    return getDbUsers(tenantId);
  });
}

export function useTrainingStatusQuery(
  tenantId: string
): UseQueryResult<UserTrainingStatus[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<UserTrainingStatus[], Error>(
    [tenantId, "training-statuses"],
    async () => {
      const token = await getInternalApiToken();
      return getUserTrainingStatuses(token, tenantId);
    }
  );
}

export function useEmployeeHandbookQuery(tenantId: string) {
  return useQuery<UserEmployeeHandbookStatus[], Error>(
    [tenantId, "handbook-statuses"],
    async () => {
      return getUserHandbookStatuses(tenantId);
    }
  );
}

export function useDevicesAppsQuery(
  tenantId: string
): UseQueryResult<Record<string, DeviceApplicationData>, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const { data } = useDevicesQuery(tenantId);
  const devices = data?.filter((device) => {
    return !IntuneMobileDeviceTypes.includes(
      device.operatingSystem.toLowerCase()
    );
  });
  return useQuery(
    [tenantId, "device-applications"],
    async () => {
      if (!tenantId) {
        throw new Error("Could not get tenant id from params");
      }
      const token = await getInternalApiToken();
      // since we use the `enabled` option below this is guaranteed to exist
      const extendedDeviceData = await Promise.all(
        devices!.map(async (device) => {
          let applications: DeviceApplication[] | null;
          try {
            applications = await getDeviceApplications(
              token,
              tenantId,
              device.id ?? "linux",
              device.displayName
            );
          } catch (error) {
            if (
              (error as Error).message === "could not find any Application logs"
            ) {
              applications = null;
            } else {
              throw error;
            }
          }
          return {
            users:
              device.registeredUsers?.map((user) => user.userPrincipalName) ??
              [],
            applications,
          };
        })
      );
      return devices!.reduce(
        (prev: Record<string, DeviceApplicationData>, device, idx) => {
          prev[device.displayName] = extendedDeviceData[idx];
          return prev;
        },
        {}
      );
    },
    {
      enabled: !!devices,
    }
  );
}

export function useDeviceProcessesQuery(
  tenantId: string
): UseQueryResult<Record<string, DeviceProcess[] | null>, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "device-processes"], async () => {
    if (!tenantId) {
      throw new Error("Could not get tenant id from params");
    }
    const token = await getInternalApiToken();
    const [msftDevices, dbDevices] = await Promise.all([
      listDevices(token, tenantId),
      listDbDevices(token, tenantId),
    ]);
    const allDevices = [...msftDevices, ...dbDevices];
    const extendedDeviceData = await Promise.all(
      [...msftDevices, ...dbDevices].map(async (device) => {
        let processes: DeviceProcess[] | null;
        try {
          processes = await getDeviceProcesses(
            token,
            tenantId,
            device.id ?? "linux", // device id is in the path but not actually used in the handler
            device.displayName
          );
        } catch (error) {
          if ((error as Error).message === "could not find any logs") {
            processes = null;
          } else {
            throw error;
          }
        }
        return processes;
      })
    );
    return allDevices.reduce(
      (prev: Record<string, DeviceProcess[] | null>, device, idx) => {
        prev[device.displayName] = extendedDeviceData[idx];
        return prev;
      },
      {}
    );
  });
}

export function useDevicesQuery(
  tenantId: string
): UseQueryResult<Device[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "devices"], async () => {
    const token = await getInternalApiToken();
    const [msDevices, dbDevices] = await Promise.all([
      listDevices(token, tenantId),
      listDbDevices(token, tenantId),
    ]);
    return [...msDevices, ...dbDevices];
  });
}

export function useTenantsQuery() {
  const { getInternalApiToken } = useMicrosoftToken();

  const fetchTenants = async () => {
    const token = await getInternalApiToken();
    const tenants = await listTenants(token);
    return tenants;
  };

  return useQuery<TenantListResponse, Error | AxiosError>(
    "tenants",
    fetchTenants
  );
}

export function useUsersAndRolesQuery(
  tenantId: string
): UseQueryResult<UserAndRoles[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "users-and-roles"], async () => {
    const token = await getInternalApiToken();
    return listUsersAndRoles(token, tenantId);
  });
}

export function useAllRegKeysQuery(
  tenantId: string
): UseQueryResult<RegKeyConfiguration, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "reg-keys"], async () => {
    const token = await getInternalApiToken();
    return getAllRegistryKeys(token, tenantId);
  });
}

export function useVpnQuery(tenantId: string): UseQueryResult<object[], Error> {
  const logType: TenantLogType = "vpnServer";
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "logs", logType], async () => {
    const token = await getInternalApiToken();
    return getTenantLogs(token, tenantId, logType);
  });
}

export function useVmConnectionQuery(
  tenantId: string
): UseQueryResult<Record<string, any>[], Error> {
  const windowsLogType: TenantLogType = "vmConnection";
  const macLogsType: TenantLogType = "socketEvents";
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "logs", windowsLogType], async () => {
    const token = await getInternalApiToken();
    const [windowsLogs, macLogs] = await Promise.allSettled([
      getTenantLogs<Record<string, any>[]>(token, tenantId, windowsLogType),
      getTenantLogs<Record<string, any>[]>(token, tenantId, macLogsType),
    ]);
    if (windowsLogs.status === "rejected" && macLogs.status === "rejected") {
      throw new Error(
        `Error getting connection logs: mac: ${macLogs.reason}, windows: ${windowsLogs.reason}`
      );
    }
    let allLogs: Record<string, any>[] = [];
    if (windowsLogs.status === "fulfilled") {
      allLogs = windowsLogs.value;
    }
    if (macLogs.status === "fulfilled") {
      allLogs = [...allLogs, ...macLogs.value];
    }
    if (allLogs.length === 0) {
      throw new Error("no logs found");
    }
    return allLogs.sort((log1, log2) => {
      if (!log1.TimeGenerated || !log2.TimeGenerated) {
        return 0;
      } else if (log1.TimeGenerated < log2.TimeGenerated) {
        return 1;
      } else {
        return -1;
      }
    });
  });
}

export function useOfficeActivityQuery(
  tenantId: string
): UseQueryResult<Record<string, object>[], Error> {
  const logType: TenantLogType = "officeActivity";
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "logs", logType], async () => {
    const token = await getInternalApiToken();
    return getTenantLogs(token, tenantId, logType);
  });
}

export function useChangeTrackingLogs(
  tenantId: string
): UseQueryResult<object[], Error> {
  const logType: TenantLogType = "changeTracking";
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "logs", logType], async () => {
    const token = await getInternalApiToken();
    return getTenantLogs(token, tenantId, logType);
  });
}

export function useUpdateManagementLogs(
  tenantId: string
): UseQueryResult<object[], Error> {
  const logType: TenantLogType = "updateManagement";
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "logs", logType], async () => {
    const token = await getInternalApiToken();
    return getTenantLogs(token, tenantId, logType);
  });
}

export function useSignInIps(
  tenantId: string
): UseQueryResult<SignInIp[], Error> {
  const logType: TenantLogType = "signInIps";
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "logs", logType], async () => {
    const token = await getInternalApiToken();
    return getTenantLogs(token, tenantId, logType);
  });
}

export function useDeviceVulnerabilities(
  tenantId: string
): UseQueryResult<DeviceVulnerability[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "device-vulnerabilities"], async () => {
    const token = await getInternalApiToken();
    return getDeviceVulnerabilities(token, tenantId);
  });
}

export function useConditionalAccessResults(
  tenantId: string
): UseQueryResult<ConditionalAccessPolicyResults[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "conditional-access-results"], async () => {
    const token = await getInternalApiToken();
    return getConditionalAccessResults(token, tenantId);
  });
}

export function useLatestHealthchecks(
  tenantId: string
): UseQueryResult<HealthcheckRow[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<HealthcheckRow[], Error>(
    [tenantId, "logs", "healthcheck"],
    async () => {
      const token = await getInternalApiToken();
      const allHcs = await getLatestHealthchecks(token, tenantId);
      const newHcs: HealthcheckRow[] = [];
      allHcs.forEach((hc) => {
        if ((hc as LegacyDeviceHealthcheck).healthcheck) {
          hc = hc as LegacyDeviceHealthcheck;
          const aegisVersion =
            hc.healthcheck.find(
              (script) => script.scriptname === "AegisVersion"
            )?.output || "unknown";
          const username =
            hc.healthcheck.find(
              (script) => script.scriptname === "UserIdentity"
            )?.output || "unknown";
          const deviceName = hc.computer;
          const id = uuidv4();
          hc.healthcheck.forEach((script) => {
            let status: HealthcheckStatus;
            if (script.status === "ERROR") {
              status = "failure";
            } else {
              status = script.status.toLowerCase() as "success" | "info";
            }
            const newRow: HealthcheckRow = {
              aegisVersion_s: aegisVersion,
              data_s: script.output,
              deviceName_s: deviceName,
              id_g: id,
              platform_s: "win32",
              SourceSystem: "RestAPI",
              status_s: status,
              stepName_s: script.scriptname.toLowerCase(),
              TenantId: tenantId,
              TimeGenerated: script.timestamp,
              Type: "Healthcheck_CL",
              username_s: username,
            };
            newHcs.push(newRow);
          });
        } else {
          newHcs.push(hc as HealthcheckRow);
        }
      });
      newHcs.sort((a, b) => {
        if (a.id_g === b.id_g) {
          if (a.stepName_s < b.stepName_s) {
            return -1;
          }
          return 1;
        }
        if (a.id_g < b.id_g) {
          return -1;
        }
        return 1;
      });
      return newHcs;
    }
  );
}

export function useUsbMountEvents(
  tenantId: string
): UseQueryResult<UsbDriveMountedEvent[], Error> {
  const logTypeWindows: TenantLogType = "usbMountEvents";
  const logTypeMacOS: TenantLogType = "usbMountEventsMac";
  const { getInternalApiToken } = useMicrosoftToken();
  const allResults: UsbDriveMountedEvent[] = [];
  return useQuery([tenantId, "logs", "allUsbMountEvents"], async () => {
    const token = await getInternalApiToken();
    const [macLogs, windowsLogs] = await Promise.allSettled([
      getTenantLogs<Record<string, string>[]>(token, tenantId, logTypeMacOS),
      getTenantLogs<Record<string, string>[]>(token, tenantId, logTypeWindows),
    ]);

    if (macLogs.status === "rejected" && windowsLogs.status === "rejected") {
      throw new Error(
        `Error getting logs: mac: ${macLogs.reason}, windows: ${windowsLogs.reason}`
      );
    }

    if (macLogs.status === "fulfilled") {
      macLogs.value.forEach((row) => {
        // format data from Mac logs
        const formattedRow: UsbDriveMountedEvent = {
          TimeGenerated: row.TimeGenerated,
          ActionType: "USB Device Mounted",
          DeviceName: row.hostname_s,
          UsbDeviceName: row.model_s,
          UsbDeviceVendor: row.vendor_s,
          UsbDeviceId: row.model_id_s,
        };
        allResults.push(formattedRow);
      });
    }

    if (windowsLogs.status === "fulfilled") {
      windowsLogs.value.forEach((row) => {
        // remove "\"" chars from weird Windows "AdditionalFields" data
        const additionalFields = JSON.parse(
          String(row.AdditionalFields).replaceAll("\\", "")
        );
        const formattedRow: UsbDriveMountedEvent = {
          // format data from Windows logs
          TimeGenerated: row.TimeGenerated,
          ActionType: "USB Device Mounted",
          DeviceName: row.DeviceName,
          UsbDeviceName: additionalFields.ProductName,
          UsbDeviceVendor: additionalFields.Manufacturer,
          UsbDeviceId: "N/A",
        };
        allResults.push(formattedRow);
      });
    }
    // sorting all logs by date
    return allResults.sort((log1, log2) => {
      if (!log1.TimeGenerated || !log2.TimeGenerated) {
        return 0;
      } else if (log1.TimeGenerated < log2.TimeGenerated) {
        return 1;
      } else {
        return -1;
      }
    });
  });
}

export function usePoamItems(
  tenantId: string
): UseQueryResult<POAMSpecificItem[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "poam-items"], async () => {
    const token = await getInternalApiToken();
    return getPoamItems(token, tenantId);
  });
}

export function useSspControls(
  tenantId: string
): UseQueryResult<AssessmentObjective[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "ssp-controls"], async () => {
    const token = await getInternalApiToken();
    return getSspControls(token, tenantId);
  });
}

export function useSspStatuses(
  tenantId: string
): UseQueryResult<CompanyAoStatusItem[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "sspStatuses"], async () => {
    const token = await getInternalApiToken();
    return getControlStatuses(token, tenantId);
  });
}

export function useSspScore(tenantId: string): UseQueryResult<SspScore, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, "sspScore"], async () => {
    const token = await getInternalApiToken();
    return getSspScore(token, tenantId);
  });
}
