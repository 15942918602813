import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Card,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { H2, H3 } from "../../../Components/Headings/Headings";
import SectionLink from "../../../Components/Links/SectionLink";
import LoadingBar from "../../../Components/LoadingBar/LoadingBar";
import { TextSection } from "../../../Components/Text/Text";
import { useTenantsQuery } from "../../../hooks/queries.hooks";

export default function SdlcSection() {
  const tenantId = useParams().tenantId as string;
  const { data, isLoading, isError, error } = useTenantsQuery();
  return (
    <>
      <H2 id="sdlc">SDLC Update Environments</H2>
      <TextSection>
        All the procedures to push new updates and security controls to{" "}
        <SectionLink sectionId="companyinfo" /> undergo the Software Development
        Life Cycle.
      </TextSection>
      <TextSection>
        All changes and updates pushed after prod are pre-approved by the
        Company Admin.
      </TextSection>
      <Text fontWeight="semibold" id="software-development-lifecycle">
        Security Architecture and Planning → Development → Quality Assurance →
        Production
      </Text>
      <Box marginY={3} />
      {isLoading && <LoadingBar />}
      {isError && (
        <Text color="red">Error getting tenants: {error.message}</Text>
      )}
      {data && (
        <Card variant="outline" padding={2}>
          <H3>Stages of Updates and Changes</H3>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Stage</Th>
                <Th>Tenant to which it is deployed</Th>
                <Th>Branch of code which is tested</Th>
                <Th>Machines</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Development</Td>
                <Td>aegisplatform.onmicrosoft.com</Td>
                <Td>AEGIS-X</Td>
                <Td>Local Atomus machines</Td>
              </Tr>
              <Tr>
                <Td>Quality Assurance</Td>
                <Td>atomus.onmicrosoft.us</Td>
                <Td>main → staging</Td>
                <Td>Local Atomus machines</Td>
              </Tr>
              <Tr>
                <Td>Production</Td>
                <Td>
                  {data.mappedTenants.find(
                    (tenant) => tenant.tenantId === tenantId
                  )?.domain || "Could not find tenant domain"}
                </Td>
                <Td>prod</Td>
                <Td>
                  Customer Machines deployed on a gradual step-by-step process
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Card>
      )}
    </>
  );
}
